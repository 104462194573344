import { lazy } from "react";
import DirectFleet from "../components/Page/DirectFleet";
import CheckOut from "../components/Page/CheckOut";
const Home = lazy(() => import("../components/Page/Home"));
const Location = lazy(() => import("../components/Page/Location"));
const TravelAgent = lazy(() => import("../components/Page/TravelAgent"));
const Review = lazy(() => import("../components/Page/Review"));
const Carausal = lazy(() => import("../components/Page/Carausal"));
const FrontFleet = lazy(() => import("../components/Page/FrontFleet"));
const Vehicle = lazy(() => import("../components/Page/vehicle"));
const ContactUs = lazy(() => import("../components/Page/ContactUs"));
const RentalPolicy = lazy(() => import("../components/Page/RentalPolicy"));
const SmsPolicy = lazy(() => import("../components/Page/SmsPolicy"));
const SmsAlerts = lazy(() => import("../components/Page/SmsAlerts"));
const FinalLocation = lazy(() => import("../components/Page/FinalLocation"));
const LocationPolicy = lazy(() => import("../components/Page/LocationPolicy"));
const Reservation = lazy(() => import("../components/Page/reservation"));
const LocationPage = lazy(() => import("../components/Page/LocationPage"));
const ChicagoLocation = lazy(() => import("../Blog/ChicagoLocation"));
const CalgaryLocation = lazy(() => import("../Blog/CalgaryLocation"));
const AtlantaLocation = lazy(() => import("../Blog/AtlantaLocation"));
const MontrealLocation = lazy(() => import("../Blog/MontrealLocation"));
const Toronto = lazy(() => import("../Blog/Toronto"));
const Ottawa = lazy(() => import("../Blog/Ottawa"));
const Orlando = lazy(() => import("../Blog/Orlando"));
const Denver = lazy(() => import("../Blog/Denver"));
const ShuttleRequestTracking = lazy(() => import("../components/Page/ShuttleRequestTracking"));

const routes = [
  {
    path: "/",
    identifier: "home",
    components: <Home />,
    isPrivate: false,
  },
  {
    path: "/reservation",
    identifier: "reservation",
    components: <Reservation />,
    isPrivate: false,
  },

  {
    path: "/Location",
    identifier: "Location",
    components: <Location />,
    isPrivate: false,
  },

  {
    path: "/TravelAgent",
    identifier: "TravelAgent",
    components: <TravelAgent />,
    isPrivate: false,
  },
  {
    path: "/ShuttleRequestTracking",
    identifier: "ShuttleTracker",
    components: <ShuttleRequestTracking />,
    isPrivate: false,
  },
  {
    path: "/Review",
    identifier: "Review",
    components: <Review />,
    isPrivate: true,
  },
  {
    path: "/Carausal",
    identifier: "Carausal",
    components: <Carausal />,
    isPrivate: true,
  },
  {
    path: "/FrontFleet",
    identifier: "FrontFleet",
    components: <FrontFleet />,
    isPrivate: true,
  },
  {
    path: "/vehicle",
    identifier: "vehicle",
    components: <Vehicle />,
    isPrivate: true,
  },
  {
    path: "/directfleet",
    identifier: "directfleet",
    components: <DirectFleet />,
    isPrivate: true,
  },
  {
    path: "/checkout",
    identifier: "checkout",
    components: <CheckOut />,
    isPrivate: true,
  },
  {
    path: "/ContactUs",
    identifier: "ContactUs",
    components: <ContactUs />,
    isPrivate: true,
  },
  {
    path: "/finallocation",
    identifier: "finallocation",
    components: <FinalLocation />,
    isPrivate: false,
  },
  {
    path: "/locationpolicy",
    identifier: "locationpolicy",
    components: <LocationPolicy />,
    isPrivate: false,
  },
  {
    path: "/locationpage",
    identifier: "locationpage",
    components: <LocationPage />,
    isPrivate: false,
  },
  {
    path: "/chicagoLocation",
    identifier: "chicagoLocation",
    components: <ChicagoLocation />,
    isPrivate: false,
  },
  {
    path: "/calgaryLocation",
    identifier: "calgaryLocation",
    components: <CalgaryLocation />,
    isPrivate: false,
  },
  {
    path: "/atlantaLocation",
    identifier: "atlantaLocation",
    components: <AtlantaLocation />,
    isPrivate: false,
  },
  {
    path: "/montrealLocation",
    identifier: "montrealLocation",
    components: <MontrealLocation />,
    isPrivate: false,
  },
  {
    path: "/RentalPolicy",
    identifier: "RentalPolicy",
    components: <RentalPolicy />,
    isPrivate: false,
  },
  {
    path: "/SmsPolicy",
    identifier: "SmsPolicy",
    components: <SmsPolicy />,
    isPrivate: false,
  },
  {
    path: "/SmsAlerts",
    identifier: "SmsAlerts",
    components: <SmsAlerts />,
    isPrivate: false,
  },
  {
    path: "/Toronto",
    identifier: "Toronto",
    components: <Toronto />,
    isPrivate: false,
  },
  {
    path: "/Ottawa",
    identifier: "Ottawa",
    components: <Ottawa />,
    isPrivate: false,
  },
  {
    path: "/Orlando",
    identifier: "Orlando",
    components: <Orlando />,
    isPrivate: false,
  },
  {
    path: "/Denver",
    identifier: "Denver",
    components: <Denver />,
    isPrivate: false,
  },
];

export default routes;
