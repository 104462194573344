// DataContext.js
import React, { createContext, useContext, useState } from "react";
export const DataContext = createContext();
export function DataContextProvider({ children }) {
  const [data, setData] = useState(null);
  const [locations, setLocations] = useState(null);
  const [Policy, setPolicy] = useState(null);
  const [Reservationdata, setReservationData] = useState(null);
  const [finalLocationSelected, setfinalLocationSelected] = useState(null);
  const [sourceFrom, setSourceFrom] = useState(null);

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        locations,
        setLocations,
        Policy,
        setPolicy,
        Reservationdata,
        setReservationData,
        finalLocationSelected, 
        setfinalLocationSelected,
        sourceFrom,
        setSourceFrom
      }}
    >
      {children}   {" "}
    </DataContext.Provider>
  );
}
