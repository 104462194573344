import React, { useEffect, useState, useContext } from "react";
import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import Navbar from "./components/layout/navbar";
import Footer from "./components/layout/Footer";
import { Suspense } from "react";
import "./App.css";
import routes from "./dummy/routes";
import { DataContextProvider } from "./components/Page/dataContext";
import axios from "axios";
import { baseURL } from "./Backend/backend";
import { DataContext } from "./components/Page/dataContext";
const App = () => {

  const navigate = useNavigate();
  
  const { setSourceFrom } = useContext(DataContext);

  useEffect(() => {
    const url = window.location.href;
    const match = url.match(/\/vehicle\/SkyScanner\/\?(.+)/);

    if (match) {
      const urlSearchParams = new URLSearchParams(url.split('?')[1]);
      const queryParams = Object.fromEntries(urlSearchParams.entries());

      const {
        pickup_date: pickupDate,
        pickup_time: pickupTime,
        dropoff_date: dropoffDate,
        dropoff_time: dropoffTime,
        rc,
        driver_age: driverAge,
        pickup_location: pickup_location,
        dropoff_location: dropoff_location,
        SIPP_Code : SkyScanner_SIPP_Code,
        skyscanner_redirectid: skyscanner_redirectid
      } = queryParams;
      // Convert the input date string to a JavaScript Date object
      const from_dateObject = new Date(pickupDate);
      const from_formattedDate = `${from_dateObject.getFullYear()}-${(from_dateObject.getMonth() + 1).toString().padStart(2, '0')}-${from_dateObject.getDate().toString().padStart(2, '0')}T11:11:00`;

      const to_dateObject = new Date(dropoffDate);
      const to_formattedDate = `${to_dateObject.getFullYear()}-${(to_dateObject.getMonth() + 1).toString().padStart(2, '0')}-${to_dateObject.getDate().toString().padStart(2, '0')}T11:11:00`;

      let pickup_post = {}
      let pickup_flag = false
      let dropoff_post = {}
      let dropoff_flag = false
      // data from rms to set in session starts
      axios.get(baseURL + "/get_country_city_airport_name").then((res) => {

        for (let i = 0; i < res.data.length; i++) {
          let posts = res.data[i];

          // coming from final location starts
          if (pickup_location != null && dropoff_location != null ) {

            if (pickup_flag == false && posts.location_id.split("-")[1]
              .toLocaleLowerCase()
              .indexOf(pickup_location.toLocaleLowerCase()) === 0
            ) {
              pickup_flag = true
              pickup_post = posts
            }
            if (dropoff_flag == false && posts.location_id.split("-")[1]
              .toLocaleLowerCase().indexOf(dropoff_location.toLocaleLowerCase()) === 0
            ) {
              dropoff_flag = true
              dropoff_post = posts
            }
            if(pickup_flag == true && dropoff_flag == true){
              break;
            }
          }
          // coming from final location ends
        }

        sessionStorage.setItem('pickLocation', JSON.stringify(pickup_post))
        sessionStorage.setItem('dropLocation', JSON.stringify(dropoff_post))
        sessionStorage.setItem('ss_pickLocation', JSON.stringify(pickup_post))
        sessionStorage.setItem('ss_dropLocation', JSON.stringify(dropoff_post))
        sessionStorage.setItem('locationAddress', pickup_post.full_address)
        sessionStorage.setItem('dropLocationAddress', dropoff_post.full_address)
        sessionStorage.setItem('country', pickup_post.country_name)

        sessionStorage.setItem('fromDate', from_formattedDate)
        sessionStorage.setItem('toDate', to_formattedDate)
        sessionStorage.setItem('startTime', pickupTime)
        sessionStorage.setItem('endTime', dropoffTime)
        sessionStorage.setItem('SkyScanner_SIPP_Code', SkyScanner_SIPP_Code)
        sessionStorage.setItem('skyscanner_redirectid', skyscanner_redirectid);
        sessionStorage.setItem('SkyScannerPage', 'landing-page')
        
        sessionStorage.setItem('locationSearch',
        pickup_post.location_id
            ? `${pickup_post.airport_code} ${pickup_post.airport_name}, ${pickup_post.country_name}`
            : `${pickup_post.airport_code} ${pickup_post.city}, ${pickup_post.country_name}`
        );
        sessionStorage.setItem('dropLocationSearch',
        dropoff_post.location_id
            ? `${dropoff_post.airport_code} ${dropoff_post.airport_name}, ${dropoff_post.country_name}`
            : `${dropoff_post.airport_code} ${dropoff_post.city}, ${dropoff_post.country_name}`
        );
        sessionStorage.setItem('lor', '')
        sessionStorage.setItem('redirected_from','SkyScanner');
        setSourceFrom('SkyScanner');
        navigate("/vehicle");
      });
      // data from rms to set in session ends
      // navigate("/vehicle");
    }
  }, [navigate]);

  return (
    // <DataContextProvider>
      <div>
        <Suspense
          fallback={
            <>
              <div
                className=""
                style={{
                  justifyContent: "center",
                  display: "flex",
                  gap: "5px",
                  position: "fixed",
                  top: "50%",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zindex: "9999",
                }}
              >
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only"></span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only"></span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </>
          }
        >
          <>
            {/* <Router> */}
            <div>
              <Navbar />
              <Routes>
                {routes.map((ele) => (
                  <Route
                    key={ele.identifier}
                    exact
                    path={ele.path}
                    element={ele.components}
                  />
                ))}
              </Routes>
              <Footer />
            </div>
            {/* </Router> */}
          </>
        </Suspense>
      </div>
    // </DataContextProvider>
  );
};

export default App;
