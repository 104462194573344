// import "./headerdown.css";
import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { baseURL } from "../../Backend/backend";

import axios from "axios";

function HeaderDown() {
  const [carData, setCarData] = useState([]);
  const fetchData = async () => {
    const response = await axios.get(baseURL + "/get_alldata_fleet_DESC");
    setCarData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  // const renderSlides = () => {
  //   const slides = [];
  //   let chunkSize = 6; // Number of data items per slide
  //   // let chunkSize = 36; // Number of data items per slide
  //   const chunkedCarData = [];
  //   if (window.screen.width <= 425) {
  //     chunkSize = 2;
  //   } else if (window.screen.width <= 768) {
  //     chunkSize = 4;
  //   } else if (window.screen.width >= 768) {
  //     chunkSize = 6;
  //   }
  //   for (let i = 0; i < carData.length; i += chunkSize) {
  //     const slideData = carData.slice(i, i + chunkSize);
  //     const slide = (
  //       <div key={i}>
  //         <div className="d-flex  justify-content-around">
  //           {slideData.map((item, index) => (
  //             <div
  //               style={{
  //                 fontWeight: "500",
  //                 fontSize: "17px",
  //                 fontFamily: "Arial",
  //               }}
  //               key={item.make}
  //             >
  //               {item.make}
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     );

  //     slides.push(slide);
  //   }
  //   return slides.reverse();
  // };

  // const renderArrowNext = (onClickHandler, label) => (
  //   <button
  //     type="button"
  //     onClick={onClickHandler}
  //     title={label}
  //     style={{
  //       // color: "#ff6000",
  //       color: "black",
  //       backgroundColor: "#ff6000",
  //       position: "absolute",
  //       top: "8%",
  //       right: "0%",
  //       border: "none",
  //     }}
  //   >
  //     <NavigateNextIcon />
  //   </button>
  // );

  // const renderArrowPrev = (onClickHandler, hasPrev, label) => (
  //   <button
  //     type="button"
  //     onClick={onClickHandler}
  //     title={label}
  //     style={{
  //       // color: "#ff6000",
  //       color: "black",
  //       backgroundColor: "#ff6000",
  //       position: "absolute",
  //       top: "0%",
  //       left: "0%",
  //       zIndex: 900,
  //       border: "none",
  //     }}
  //   >
  //     <NavigateBeforeIcon />
  //   </button>
  // );

  return (
    <>
      <div
        className="col-12 col-md-12"
        style={{
          // backgroundColor: "white",
          backgroundColor: "#344996",
          height: "0.1rem",
          boxShadow: "0px 0px 10px #ff6000",
        }}
      >
        {/* <Carousel
          infiniteLoop={true}
          arrows
          autoPlay={true}
          centerMode
          centerSlidePercentage={100}
          showIndicators={false}
          showArrows={true}
          renderArrowNext={renderArrowNext}
          renderArrowPrev={renderArrowPrev}
          autoFocus={true}
          showStatus={false}
          className="container carousel relative"
          interval={2000}
        >
          {renderSlides()}
        </Carousel> */}
      </div>
    </>
  );
}

export default HeaderDown;
