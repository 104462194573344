import React, { useEffect, useState } from "react";
import { baseURL } from "../../Backend/backend";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./directfleet.css";
import Default from "./asset/BigCar.jpg";

function DirectFleet() {
  const navigate = useNavigate();
  const [FleetData, setFleetData] = useState([{}]);

  const [showCarInfo, setShowCarInfo] = useState(false);
  const [Cardata, setCarData] = useState(false);

  const fetchData = async () => {
    const response = await axios.get(
      baseURL + "/get_sippcode_make_model_carimg_data"
    );

    let temp_t = {};
    for (let i = 0; i < response.data.length; i++) {
      temp_t[i] = false;
    }
    setFleetData(response.data);
    setShowCarInfo(temp_t);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const toggleInfoTwo = () => {
    setCarData(!Cardata);
  };

  const toggleInfo = (i) => {
    let temp_hash = { ...showCarInfo };
    temp_hash[i] = !temp_hash[i];
    setShowCarInfo(temp_hash);
  };
  const handleBookNowClick = () => {
    window.location.href = "/";
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div
        className="container-fluid"
        style={{ backgroundColor: "rgb(243, 242, 239)" }}
      >
        <div className="form mx-1">
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 main-form">
            {FleetData.map((item, i) => (
              <div
                className="sub-col col-xl-3 col-lg-4 col-md-5 col-sm-5 col-xs-11 col-xs-12 m-2"
                style={{ backgroundColor: "white" }}
              >
                <div className="card-body">
                  <div className="row heading ">
                    <div className="col-8 veh-info-left">
                      <h6 className="card-title text-left px-2 mx-2 mt-4">
                        {item.fleet_standard}
                      </h6>
                      <p className="card-text text-left px-2 mx-2">
                        {item.make} &nbsp;
                        {item.model} or Similar
                      </p>
                    </div>
                    <div className="col-4 veh-info-right mt-5 px-0">
                      <span
                        href="#"
                        className="veh-a mx-2"
                        onClick={() => toggleInfo(i)}
                      >
                        {showCarInfo[i] ? (
                          <>
                            <label style={{ cursor: "pointer" }}>
                              Close Info
                            </label>
                            <span class="fa-solid fa-chevron-down mx-1"></span>
                          </>
                        ) : (
                          <>
                            <label style={{ cursor: "pointer" }}>
                              Vehicle Info
                            </label>
                            <span class="fa-solid fa-angle-down mx-1"></span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>

                  {showCarInfo[i] ? (
                    <div className="group-info">
                      {/* <div className="car-info p-1 mt-2 mb-4   d-flex d-md-block flex-row "> */}
                      <div className="car-info p-1 mt-2 mb-4">
                        <div className="row d-flex veh-row">
                          <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                            <i class="fa-solid fa-ban-smoking me-1 mt-1"></i>
                            <span>
                              <label className="ve-info mb-2">Smoke Free</label>
                            </span>
                          </div>
                          <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                            <i class="fi fi-bs-person-seat-reclined me-1 mt-1"></i>
                            <span>
                              <label className="ve-info mb-2">
                                {item.seat_count === undefined
                                  ? "4 Seats"
                                  : item.seat_count}{" "}
                                {"Seats"}
                              </label>
                            </span>
                          </div>
                          <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                            {/* <i class="fi fi-br-air-conditioner me-1 mt-1"></i> */}
                            <i class="fi fi-sr-air-conditioner me-1 mt-1"></i>
                            <span>
                              <label className="ve-info mb-2">A/C</label>
                            </span>
                          </div>
                        </div>
                        <div className="row d-flex veh-row">
                          <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                            <i class="fa-solid fa-user-gear me-1 mt-1"></i>
                            <span>
                              <label className="ve-info mb-2">Automatic</label>
                            </span>
                          </div>
                          <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                            <i
                              class="fas fa-suitcase me-1 mt-1"
                              style={{ fontSize: "20px" }}
                            ></i>
                            <span>
                              <label className="ve-info mb-2">
                                1 Large Bag
                              </label>
                            </span>
                          </div>
                          <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                            <i
                              class="fas fa-suitcase me-1 mt-1"
                              style={{ fontSize: "16px" }}
                            ></i>
                            <span>
                              <label className="ve-info mb-2">
                                2 Small Bag
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="card-main"
                      style={{
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={baseURL + "/static/img/" + item.image_path}
                        className="card-car-img img-thumbnail"
                        alt="..."
                        style={{
                          width: "auto",
                          height: "120px",
                          border: "none",
                          // objectFit: "contain",
                        }}
                      />
                    </div>
                  )}

                  <div className="row mb-1">
                    <div
                      className="col-12 d-flex justify-content-center"
                      id="button"
                    >
                      {/* <Link to="/"> */}
                        <button
                          className="btn btn-warning text-white mt-2"
                          onClick={handleBookNowClick}
                        >
                          Book Now
                        </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div
              className="sub-col col-xl-3 col-lg-4 col-md-5 col-sm-5 col-xs-11 col-xs-12 m-2"
              style={{ backgroundColor: "white" }}
            >
              <div className="card-body">
                <div className="row heading">
                  <div className="col-8 veh-info-left">
                    <h6 className="card-title text-left px-2 mx-2 mt-4">
                      {/* {item.SIPP_code} */}
                      Commercial
                    </h6>
                    <p className="card-text text-left px-2 mx-2">
                      {/* {item.make} &nbsp; */}
                      {/* {item.model} or Similar */}
                      Ford E340 Cargo Box Truck
                    </p>
                  </div>
                  <div className="col-4 veh-info-right mt-5 px-0">
                    <span
                      href="#"
                      className="veh-a mx-2"
                      onClick={() => toggleInfoTwo()}
                    >
                      {Cardata ? (
                        <>
                          <label style={{ cursor: "pointer" }}>
                            Close Info
                          </label>
                          <span class="fa-solid fa-chevron-down mx-1"></span>
                        </>
                      ) : (
                        <>
                          <label style={{ cursor: "pointer" }}>
                            Vehicle Info
                          </label>
                          <span class="fa-solid fa-angle-down mx-1"></span>
                        </>
                      )}
                    </span>
                  </div>
                </div>

                {Cardata ? (
                  <div className="group-info">
                    {/* <div className="car-info p-1 mt-2 mb-4 d-sm-flex d-md-none"> */}
                    <div className="car-info p-1 mt-2 mb-4">
                      <div className="row d-flex veh-row">
                        <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                          <i class="fa-solid fa-ban-smoking me-1 mt-1"></i>
                          <span>
                            <label className="ve-info mb-2">Smoke Free</label>
                          </span>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                          <i class="fi fi-bs-person-seat-reclined me-1 mt-1"></i>
                          <span>
                            <label className="ve-info mb-2">{"5 Seats"}</label>
                          </span>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                          {/* <i class="fi fi-br-air-conditioner me-1 mt-1"></i> */}
                          <i class="fi fi-sr-air-conditioner me-1 mt-1"></i>

                          <span>
                            <label className="ve-info mb-2">A/C</label>
                          </span>
                        </div>
                      </div>
                      <div className="row d-flex veh-row">
                        <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                          <i class="fa-solid fa-user-gear me-1 mt-1"></i>
                          <span>
                            <label className="ve-info mb-2">Automatic</label>
                          </span>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                          <i class="fi fi-bs-car me-1 mt-1"></i>
                          <span>
                            <label className="ve-info mb-2">
                              Van/Truck Comm
                            </label>
                          </span>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center mt-2 mb-2">
                          {/* <i class="fi fi-bs-car me-1 mt-1"></i> */}
                          <span>
                            {/* <label className="ve-info mb-2">2 Small Bag</label> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="card-main"
                    style={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={Default}
                      className="card-car-img img-thumbnail"
                      alt="..."
                      style={{
                        width: "auto",
                        height: "120px",
                        border: "none",
                        // objectFit: "contain",
                      }}
                    />
                  </div>
                )}

                {/* <div className="row mb-1">
                  <div
                    className="col-12 d-flex justify-content-center"
                    id="button"
                  >
                    <Link to="/" target="_blank">
                      <button
                        className="btn btn-warning text-white mt-2"
                        onClick={handleBookNowClick}
                      >
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div> */}
                <div className="row mb-1">
                  <div
                    className="col-12 d-flex justify-content-center"
                    id="button"
                  >
                    <Link to="/">
                      <button
                        className="btn btn-warning text-white mt-2"
                        onClick={handleBookNowClick}
                        style={{  }}
                      >
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectFleet;
