import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseURL } from "../../Backend/backend";
import { Country, State, City } from "country-state-city";
import IATA from "./IATA.json";
import Select from "react-select";
import Swal from "sweetalert2";
import { DataContext } from "./dataContext";
// import { DatePicker } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./checkout.css";
import moment from "moment";
import routesCarImage from "./asset/routeslogo.png";
import europCarImage from "./asset/europcar_img1.jpg";
import { AES, enc } from "crypto-js";
import RefreshIcon from "@mui/icons-material/Refresh";
import cc_visa from "./asset/cards-cc_visa.svg"
import cc_american_express from "./asset/cards-cc_american_express.svg"
import cc_discover from "./asset/cards-cc_discover.svg"
import cc_master_card from "./asset/cards-cc_master_card.svg"
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


const CheckOut = () => {
  // const PUBLISHABLE_KEY = "pk_test_0knnaAcQeAPPSsSL9UP6uBg4005rjvcxQH";  //Test
  const PUBLISHABLE_KEY = "pk_live_FJVMngbG2a670s9v11N6QXlT005XQiwub3"; //Live

  // const secretKey = process.env.REACT_APP_SECRET_KEY_DECRYPT; 
  const secretKey = 'mnbvc4567;.;lkjh][9876vfr567yhgb`1q=-pl}{KL+_$E@';

  if (!sessionStorage.getItem("selected_car_details")) {
    window.location.href = '/';
  }
  let navigate = useNavigate();
  const hours = [
    { Year: "00:00" },
    { name: "00:30" },
    { name: "01:00" },
    { name: "01:30" },
    { name: "02:00" },
    { name: "02:30" },
    { name: "03:00" },
    { name: "03:30" },
    { name: "04:00" },
    { name: "04:30" },
    { name: "05:00" },
    { name: "05:30" },
    { name: "06:00" },
    { name: "06:30" },
    { name: "07:00" },
    { name: "07:30" },
    { name: "08:00" },
    { name: "08:30" },
    { name: "09:00" },
    { name: "09:30" },
    { name: "10:00" },
    { name: "10:30" },
    { name: "11:00" },
    { name: "11:30" },
    { name: "12:00" },
    { name: "12:30" },
    { name: "13:00" },
    { name: "13:30" },
    { name: "14:00" },
    { name: "14:30" },
    { name: "15:00" },
    { name: "15:30" },
    { name: "16:00" },
    { name: "16:30" },
    { name: "17:00" },
    { name: "17:30" },
    { name: "18:00" },
    { name: "18:30" },
    { name: "19:00" },
    { name: "19:30" },
    { name: "20:00" },
    { name: "20:30" },
    { name: "21:00" },
    { name: "21:30" },
    { name: "22:00" },
    { name: "22:30" },
    { name: "23:00" },
    { name: "23:30" },
    { name: "23:59" },
  ];
  const { setReservationData } = useContext(DataContext);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [ConfEmailId, setConfEmailID] = useState("");
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [airportCodeData, setAirtportCodeData] = useState([]);
  const [fname, setFName] = useState("");
  const [uniqeid, setUniqueId] = useState("");
  const [DOB, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [lname, setLname] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [flight_number, setflight_number] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [EmailId, setEmailID] = useState("");
  const [CustomerAddr, setCustomerAddr] = useState("");
  const [CardNumber, setCardNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [CardName, setCardName] = useState("");
  const [CVVNum, setCvvnum] = useState("");
  const [ExpiryDate, setExpiryDate] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [policyDetails, setPolicyDetails] = useState([]);
  const [captchaValue, setCaptchaValue] = useState("");
  const [extrasTotal, setExtrasTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [Loading, setLoading] = useState(false);
  const jsonString1 = sessionStorage.getItem("selected_car_details");
  const jsonString = AES.decrypt(jsonString1, secretKey).toString(enc.Utf8);

  const data = JSON.parse(jsonString);
  const [Extras, setExtras] = useState(data.Extras);
  const jsonStrings = sessionStorage.getItem("lor");
  const datas = jsonStrings; //JSON.parse(jsonStrings);

  const pickLocation = JSON.parse(sessionStorage.getItem("pickLocation"));
  const dropLocation = JSON.parse(sessionStorage.getItem("dropLocation"));
  const startTime = sessionStorage.getItem("startTime");
  const endTime = sessionStorage.getItem("endTime");
  const fromDate = new Date(sessionStorage.getItem("fromDate"));
  const toDate = new Date(sessionStorage.getItem("toDate"));
  const locationSearch = sessionStorage.getItem("locationSearch");
  const DropLocationSearch = sessionStorage.getItem("dropLocationSearch");
  const rate_code_selected = sessionStorage.getItem("rate_code");
  const [showConverge, setShowConverge] = useState(false);
  const [itemDetails, setItemDetails] = useState({});
  const [amountToPay, setAmountToPay] = useState("");
  const [convergePayLoading, setConvergePayLoading] = useState(false);
  // skyscanner starts
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [ss_final_key_value, setss_final_key_value] = useState("");
  const [ss_final_key_value_pre, setss_final_key_value_pre] = useState("");
  const [ss_skyscanner_redirect, setss_skyscanner_redirect] = useState(sessionStorage.getItem("redirected_from"));
  const ss_tracking_source_id = 'DV-942169-3';
  // skyscanner ends
  // captcha starts
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const dataString = sessionStorage.getItem("pickLocation"); // Replace 'yourKey' with the actual key you used to store the data
  const dataString_drop = sessionStorage.getItem("dropLocation"); // Replace 'yourKey' with the actual key you used to store the data
  // Parse the JSON string into an object
  const dataObject = JSON.parse(dataString);
  const dataObject_dropoff = JSON.parse(dataString_drop);
  // Extract the 'source' value from the object
  const pickup_city = dataObject.city;
  const dropoff_city = dataObject_dropoff.city;
  let pickup_area_temp = 'City'
  let dropoff_area_temp = 'City'
  if (dataObject.location_id != undefined) {
    if (dataObject.location_id.split("-")[1].length === 3
      || (dataObject.location_id.split("-")[1].length > 3 && dataObject.location_id.split("-")[1][3] === 'T')) {
      pickup_area_temp = 'Airport'
    }
  }
  const pickup_area = pickup_area_temp
  if (dataObject_dropoff.location_id != undefined) {
    if (dataObject_dropoff.location_id.split("-")[1].length === 3
      || (dataObject_dropoff.location_id.split("-")[1].length > 3 && dataObject_dropoff.location_id.split("-")[1][3] === 'T')) {
      dropoff_area_temp = 'Airport'
    }
  }
  const dropoff_area = dropoff_area_temp
  const sourceValue = dataObject.source;

  useEffect(() => {
    if (sessionStorage.getItem("location_currency") != 'USD') {
      // Load Stripe script
      const script = document.createElement('script');
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.StripeCheckout.configure({
          key: PUBLISHABLE_KEY,
          locale: 'auto',
        });
      };

      return () => {
        // Clean up the script when the component is unmounted
        document.body.removeChild(script);
      };
    }
  }, []);

  // Now, 'sourceValue' contains the value of the 'source' item
  useEffect(() => {
    generateCaptcha();
    let extras_total = 0;
    let extras_total_tax = 0;
    let extras_details = data.Extras;
    for (let i = 0; i < extras_details.length; i++) {
      if (extras_details[i].count > 0) {
        extras_total_tax = extras_total_tax + +extras_details[i].tax;
        extras_total = extras_total + +extras_details[i].total;
      }
    }
    if (ss_skyscanner_redirect != undefined && ss_skyscanner_redirect != null && ss_skyscanner_redirect == 'SkyScanner') {
      let ss_detail1 = sessionStorage.getItem("SkyScanner_pre_conversion_body");
      const secretKey = "mnbvc4567;.;lkjh][9876vfn567yhgb`1q=-pl}{KL+_!E@";
      ss_detail1 = AES.decrypt(ss_detail1, secretKey).toString(enc.Utf8);
      ss_detail1 = JSON.parse(ss_detail1);
      const currentDate = new Date();
      const isoDateString = currentDate.toISOString();
      ss_detail1 = ss_detail1 + "&price=" + (data.price_after_taxes + +extras_total).toFixed(2)
        + "&" + "occurred_at=" + encodeURIComponent(isoDateString) + "&" + "stage=checkout-page";
      setss_final_key_value_pre(ss_detail1);
    }
    setExtrasTotal(extras_total);
  }, []);

  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaText(captcha);
  };
  const handleCloses = () => {
    setShow(false);
  };

  const handleRedirectHomes = () => {
    setRedirectToHome(true);
    setShow(false);
  };
  useEffect(() => {
    if (!show && userInput != "") {
      setRedirectToHome(true);
    }
  }, [show]);

  useEffect(() => {
    if (redirectToHome) {
      // Redirect logic here (e.g., using React Router)
      window.location.href = "/"; // Change this to your desired route
    }
  }, [redirectToHome]);
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts

  }, []);
  // captcha ends
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleCheckboxChange2 = (e) => {
    setIsChecked2(e.target.checked);
  };

  const [startHour, startMinute, startSecond] = startTime.split(":");
  const [endHour, endMinute, endSecond] = endTime.split(":");
  const formattedStartTime = `${startHour}:${startMinute}:${startSecond}`;
  const formattedEndTime = `${endHour}:${endMinute}${endSecond}`;
  const [rezNum, setRezNum] = useState("");

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleCountry = (value) => {
    const data = State.getAllStates();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === value?.isoCode) filteredData.push(item);
    }
    setStateData(filteredData);
    setState("");
  };
  const Currency = sessionStorage.getItem("location_currency");
  const currency_symbol = sessionStorage.getItem("currency_symbol");
  const handleState = (value) => {
    let filteredData = [];
    const data = City.getAllCities();
    for (let item of data) {
      if (
        value?.countryCode === item?.countryCode &&
        value?.isoCode === item?.stateCode
      ) {
        filteredData.push(item);
      }
    }
    setCityData(filteredData);
    setCity("");
  };

  const handleCity = (value) => {
    let filteredData = [];
    for (let item of IATA) {
      if (
        value.name != "" &&
        value.name != null &&
        value.name.toString().trim().toLowerCase() ===
        item?.city.toString().trim().toLowerCase() &&
        item.iata != ""
      ) {
        filteredData.push(item);
      }
    }

    if (filteredData.length === 0) {
      for (let item of IATA) {
        if (
          country.name != "" &&
          country.name != null &&
          country.name.toString().trim().toLowerCase() ===
          item?.country.toString().trim().toLowerCase() &&
          item.iata != ""
        ) {
          filteredData.push(item);
        }
      }
    }

    setAirtportCodeData(filteredData);
  };
  const date_formater = (inputDateString) => {
    // Parse the input date
    var parsedDate = new Date(inputDateString);
    parsedDate.setHours(18);
    parsedDate.setMinutes(30);
    parsedDate.setSeconds(0);
    parsedDate.setMilliseconds(0);

    // Extract the date components
    var year = parsedDate.getFullYear();
    var month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    var day = String(parsedDate.getDate()).padStart(2, "0");
    var hours = String(parsedDate.getHours()).padStart(2, "0");
    var minutes = String(parsedDate.getMinutes()).padStart(2, "0");
    var seconds = String(parsedDate.getSeconds()).padStart(2, "0");
    var milliseconds = String(parsedDate.getMilliseconds()).padStart(3, "0");

    // Format the date string in the desired format
    var formattedDate =
      year +
      "-" +
      month +
      "-" +
      day +
      "T" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      "." +
      milliseconds;

    return formattedDate;
  };

  function isValidEmail(email) {
    // Regular expression for a valid email address
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // Use the test method of the regex pattern to check if the email is valid
    return emailPattern.test(email);
  }
  function isValidAddress(address) {
    // Regular expression for a valid email address
    const addressPattern = /^[A-Za-z.,_0-9\s]+$/;
    // Use the test method of the regex pattern to check if the email is valid
    return addressPattern.test(address);
  }

  const maxDate = new Date(); // Set max date to today
  const onChange = (date) => {
    try {
      if (date > maxDate) {
        return; // Do nothing if the selected date is in the future
      }

      const formattedDate = date.toLocaleString();
      setDOB(date);

      // const after2Day = date
      //   ? new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000)
      //   : null;
      // setDOB(date ? new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000) : null);
      // const formattedDate2 = after2Day.toLocaleString();
    } catch (error) { }
  };
  function getMonthNumber(monthName) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = monthNames.indexOf(monthName);
    return monthIndex !== -1 ? monthIndex + 1 : null;
  }
  const dateString = ExpiryDate;
  const dateObj = new Date(dateString);
  const month = getMonthNumber(
    dateObj.toLocaleString("en-US", { month: "long" })
  );
  const year = dateObj.getFullYear();

  const handlePayNow = (e) => {
    e.preventDefault();
    // Uncomment this
    if (
      !fname ||
      !lname ||
      !PhoneNumber ||
      !EmailId ||
      !ConfEmailId ||
      !DOB ||
      !CustomerAddr ||
      !country ||
      !state ||
      !zipcode
    ) {
      Swal.fire({
        icon: "error",
        title: "Please fill out all fields.",
      });

      return;
    }
    if (
      PhoneNumber.toString().length > 12 ||
      PhoneNumber.toString().length < 8
    ) {
      Swal.fire({
        icon: "error",
        title: "Please enter valid phone number to proceed ahead",
      });

      return;
    }
    if (!isValidEmail(EmailId)) {
      Swal.fire({
        icon: "error",
        title: "Please enter valid email to proceed ahead",
      });

      return;
    }
    if (EmailId != ConfEmailId) {
      Swal.fire({
        icon: "error",
        title: "Confirm Email Id is not matching with Entered Email address",
      });

      return;
    }
    if (!isValidAddress(CustomerAddr)) {
      Swal.fire({
        icon: "error",
        title: "Please do not use any special characters in the address.",
      });

      return;
    }

    if (!isChecked) {
      Swal.fire({
        icon: "error",
        title: "Please accept our Rental Policy to proceed ahead",
      });

      return;
    }
    if (captchaText !== userInput) {
      Swal.fire({
        icon: "error",
        title: "Invalid Captcha",
      });
      setUserInput("");
      generateCaptcha();
      return;
    }

    let extras_total = 0;
    let extras_total_tax = 0;
    let individual_extras_arr = [];
    let extras_details = data.Extras;
    for (let i = 0; i < extras_details.length; i++) {
      if (extras_details[i].count > 0) {
        extras_total_tax = extras_total_tax + +extras_details[i].tax;
        extras_total = extras_total + +extras_details[i].total;
        individual_extras_arr.push(
          extras_details[i].name + " : " + extras_details[i].base_rate
        );
      }
    }
    setExtrasTotal(extras_total);

    let taxes_total = 0;
    let individual_taxes_arr = [];

    let taxes_details = [];
    try {
      taxes_details = Object.keys(data.taxes_detail.independent_tax_charge);
    } catch (e) {
      taxes_details = [];
    }
    for (let i = 0; i < taxes_details.length; i++) {
      individual_taxes_arr.push(
        taxes_details[i] +
        " : " +
        data.taxes_detail.independent_tax_charge[taxes_details[i]]
      );
    }
    try {
      taxes_details = Object.keys(data.taxes_detail.baserate_tax_charge);
    } catch (e) {
      taxes_details = [];
    }
    for (let i = 0; i < taxes_details.length; i++) {
      individual_taxes_arr.push(
        taxes_details[i] +
        " : " +
        data.taxes_detail.baserate_tax_charge[taxes_details[i]]
      );
    }
    try {
      taxes_details = Object.keys(data.taxes_detail.calculated_tax_charge);
    } catch (e) {
      taxes_details = [];
    }
    for (let i = 0; i < taxes_details.length; i++) {
      individual_taxes_arr.push(
        taxes_details[i] +
        " : " +
        data.taxes_detail.calculated_tax_charge[taxes_details[i]]
      );
    }

    let item = {
      OTA: "Routes Website",
      Booked_ip: "",
      DOB: DOB,
      Age: "",
      Address: CustomerAddr, // user_details["address"],
      // Rate_Code: "3", // className_details["RateId"],

      // Rate_Code: "34", // className_details["RateId"],
      Rate_Code: rate_code_selected === undefined ? "34" : rate_code_selected, // className_details["RateId"],
      Pickup_Location: pickLocation.location_id.split("-")[1], //className_details["locationCode"],
      DropOff_Location: dropLocation.location_id.split("-")[1], //className_details["to_location"],
      // Pickup_Date_Time: fromDate, //"2023-10-10T10:10:10.000", // datetime
      Pickup_Date_Time:
        date_formater(new Date(fromDate)).split("T")[0] +
        "T" +
        startTime +
        ":00.000", //"2023-10-10T10:10:10.000", // datetime
      // Pickup_Date_Time: `${fromDate}T${startTime}:00.000`, //"2023-10-10T10:10:10.000", // datetime
      DropOff_Date_Time:
        date_formater(new Date(toDate)).split("T")[0] +
        "T" +
        endTime +
        ":00.000", //"2023-10-10T10:10:10.000", // datetime
      Booking_Date_Time: "2023-10-10T10:10:10.000", //datetime.now().strftime("%Y-%m-%dT%H:%M:%S.%f%z"),
      SIPP_Code: data.fleet_detail.SIPP_code, //className_details["classNameCode"],
      First_Name: fname, //user_details["name"],
      Last_Name: lname, //user_details["l_name"],
      Email_ID: EmailId, //user_details["email"],
      Phone_No: PhoneNumber, //user_details["phone"],
      city: city?.name, //user_details["city"],
      state: state?.name, //user_details["state"],
      country: country?.name, //user_details["country"],
      zip_code: zipcode, //user_details["zip_code"],
      flight_number: flight_number, //user_details["zip_code"],
      flight: "", //user_details["flight"],
      uniqe_id: "", //user_details["uniqe_id"],
      // c_name: CardName, //user_details["c_name"],
      // c_number: CardNumber, //"4242424242424242", // user_details["c_number"],
      // c_expiry_m: month.toString(),
      // c_expiry_y: year.toString(),
      // c_code: CVVNum, //user_details["c_code"],
      confirm_email: ConfEmailId, //user_details["confirm_email"],
      pay_type: "", //user_details["pay_type"],
      accept_terms: "", //user_details["accept_terms"],
      Base_Price_rate_table: +(+data.price / +datas).toFixed(2), //"", //breakup_detail["Base_price_per_day"],

      Total_Price: data.price_after_taxes, // breakup_detail["Total_amount"],
      Car_Price: data.price, //breakup_detail["Total_base_price"],
      Tax_on_Car_Price_Value: data.total_taxes, //breakup_detail["Total_tax"],
      Tax_on_Car_Price_Details_Breakup:
        individual_taxes_arr.length > 0 ? individual_taxes_arr.toString() : "", //tax_detail_individual,
      // Extras per price
      Extras_Total_Price: extras_total, //extras_details["extra_total_amount"],
      Extras_Base_Value: +extras_total - +extras_total_tax, //
      Tax_on_Extras: extras_total_tax, // extras_details["extra_total_tax"],
      Currency: sessionStorage.getItem("location_currency"), // className_details["CurrencyCode"],
      Tax_on_Extras_Details:
        individual_extras_arr.length > 0
          ? individual_extras_arr.toString()
          : "", // extras_detail_individual,
      upload_to_TSD: 0,
      upload_to_rent_centric: 0,
      upload_to_mexico_file: 2,
      Account_ID: pickLocation.location_id.split("-")[0], //"7654332", // "",
      length_of_rental: datas, // className_details["Days"],
      Status_Valid_Cancelled_No_Show: "Valid",
      driver_country: sessionStorage.getItem("country"),
      pickup_country: JSON.parse(sessionStorage.getItem("pickLocation")).country_name,
      Promocode: sessionStorage.getItem("promo_code"),
      skyscanner_redirectid: sessionStorage.getItem("skyscanner_redirectid"),
      booking_from: "en"
    };

    Swal.fire({
      title: "Do you want to place this reservation?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      // denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const amount = 1 * item['Extras_Base_Value'] + 1 * item['Tax_on_Extras'] + 1 * item['Total_Price']

        if (sessionStorage.getItem("location_currency") === 'USD') {
          // ============Converge for USD================
          setShowConverge(true);
          // c_name: CardName, //user_details["c_name"],
          // c_number: CardNumber, //"4242424242424242", // user_details["c_number"],
          // c_expiry_m: month.toString(),
          // c_expiry_y: year.toString(),
          // c_code: CVVNum, //user_details["c_code"],

          setItemDetails(item);
          setAmountToPay(amount);

        }
        else {
          // ============Stripe - Other than USD=================

          const paymentHandler = window.StripeCheckout.configure({
            key: PUBLISHABLE_KEY,
            locale: 'auto',
            token: async function (stripeToken) {
              try {
                const token = stripeToken.id; //This we have to send to rms backend for further steps - Create Cuatomer and create Charge
                if (token) {
                  item['token'] = token;
                  setLoading(true);
                  await processPayment(item);
                } else {
                  Swal.fire({
                    position: "middle",
                    icon: "error",
                    title: "Invalid Card Details",
                    // text: "Please try after sometime",
                    showCancelButton: false,
                  });
                  setLoading(false);
                  setUserInput("");
                  generateCaptcha();
                }

              } catch (error) {
                console.error('Error processing payment:', error);
                Swal.fire({
                  position: "middle",
                  icon: "error",
                  title: "Invalid Card Details",
                  // text: "Please try after sometime",
                  showCancelButton: false,
                });
                setLoading(false);
                setUserInput("");
                generateCaptcha();
                // setError(error.response?.data?.error || 'Payment failed. Please try again.');
              }

            }
          })

          paymentHandler.open({
            name: 'Routes Car Rental',
            description: '',
            amount: amount * 100,
            email: EmailId,  // You can replace this with the actual customer email
          });

        }

      } else if (result.isDenied) {
        setUserInput("");
        generateCaptcha();
        setLoading(false);
        Swal.fire("Booking Failed", "", "info");
      }
    });
    // .finally(() => setLoading(false));
  };

  const processPayment = async (item) => {

    setConvergePayLoading(true);
    await axios
      .post(baseURL + "/new_routes_website_api_add_reservation", item)
      .then((response) => {
        if (response.data.success_text === "success") {
          setShowConverge(false);
          const text = response.data.rez_number; // Replace this with actual response
          setRezNum(text);
          setShow(true);
          setConvergePayLoading(false);
          // sky scanner starts
          if (sessionStorage.getItem("SkyScanner_SIPP_Code")) {
            const currentDate = new Date();
            const isoDateString = currentDate.toISOString();
            const currency_1 = sessionStorage.getItem("location_currency")

            let ss_temp_key_value = "skyscanner_redirectid=" + sessionStorage.getItem("skyscanner_redirectid") + "&" +
              "tracking_source_id=" + ss_tracking_source_id + "&" +
              "event_type=conversion&" +
              "occurred_at=" + encodeURIComponent(isoDateString) + "&" +
              "conversion_id=" + text + "&" +
              "transaction_total=" + (data.price_after_taxes + +extrasTotal).toFixed(2) + "&" +
              "payment_fee=0.00&" +
              "rental_cost=" + data.price_after_taxes.toFixed(2) + "&" +
              "currency=" + currency_1 + "&" +
              "payment_method=credit" +
              "pick_up_location=" + pickup_city + "&" +
              "pick_up_location_type=" + pickup_area + "&" +
              "drop_off_location=" + dropoff_city + "&" +
              "drop_off_location_type=" + dropoff_area + "&" +
              "sipp_code=" + data.fleet_detail.SIPP_code + "&" +
              "pick_up_date=" + date_formater(new Date(fromDate)).split("T")[0] + "&" +
              "drop_off_date=" + date_formater(new Date(toDate)).split("T")[0] + "&" +
              "car_supplier=Routes";

            setss_final_key_value(ss_temp_key_value)
            setPaymentConfirmed(true);
          }
          // sky scanner ends
          // store user detail using ip start
          axios
            .get("https://ipapi.co/json")
            .then((response2) => {
              let item2 = {}
              let user_ip_details = response2.data;
              item2['reservation_number'] = text
              item2['user_ip_address'] = user_ip_details.ip
              item2['user_city'] = user_ip_details.city
              item2['user_region'] = user_ip_details.region
              item2['user_country_name'] = user_ip_details.country_name
              item2['user_latitude'] = user_ip_details.latitude
              item2['user_longitude'] = user_ip_details.longitude
              axios
                .post(baseURL + "/new_routes_website_api_store_user_ip_detail", item2)
                .then((response1) => {

                }).catch((error) => {
                  console.error("Error fetching data1:", error);
                });
            }).catch((error) => {
              console.error("Error fetching data2:", error);
            });
          // store user detail using ip ends
        } else {
          Swal.fire({
            position: "middle",
            icon: "error",
            title: response.data.success_text,
            // text: "Please try after sometime",
            showCancelButton: false,
          });
          setUserInput("");
          generateCaptcha();
          setLoading(false);
          setConvergePayLoading(false);
        }
      })
      .finally(() => setLoading(false));
  }


  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  const handleOpenModal = () => {
    axios
      .get(
        baseURL +
        "/new_routes_website_api_all_policies/" +
        pickLocation.location_id.split("-")[1]
      )
      .then((res) => {
        setPolicyDetails(res.data);
        setIsModalOpen(!isModalOpen);
      });
  };
  // const navigate = useHistory();
  const handleRedirectHome = () => {
    navigate("/"); // Redirect to the home page
    window.location.href = "/";
  };
  function isFutureDate(date) {
    const today = new Date();
    return date.isAfter(today, -1);
  }

  const disabledDate = (current) => {
    return current && isFutureDate(current);
  };

  // for debit expiry
  // const handleDateChange = (date, dateString) => {
  //   setExpiryDate(date);
  //   console.log(date, dateString);
  // };

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const filterPastMonths = (date) => {
    const currentDate = new Date();
    return date >= currentDate;
  };
  const onChangetwo = (date) => {
    try {
      const formattedDate = date.toLocaleString();
      setExpiryDate(date);
      //  sessionStorage.setItem("fromDate", formattedDate);

      const after2Day = date
        ? new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000)
        : null;
      setExpiryDate(
        date ? new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000) : null
      );
      const formattedDate2 = after2Day.toLocaleString();
      //  sessionStorage.setItem("toDate", formattedDate2);
    } catch (error) { }
  };

  const disabledDates = (current) => {
    // Disable dates that are before the start of the current month
    return (
      current &&
      current < new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );
  };
  const handleFirstName = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only letters
    const isValid = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValid || inputValue === "") {
      setFName(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage(Swal.fire("Only Alphabets are allowed!"));
    }
  };
  const handleLasttName = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only letters
    const isValid = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValid || inputValue === "") {
      setLname(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage(Swal.fire("Only Alphabets are allowed!"));
    }
  };
  const handlePhoneNumber = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only numbers
    const isValid = /^\d+$/.test(inputValue);

    if (isValid || inputValue === "") {
      setPhoneNumber(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage(Swal.fire("Only Numbers are allowed!"));
    }
  };
  const handleCardNumberChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only numbers
    const isValid = /^\d+$/.test(inputValue);

    if (isValid || inputValue === "") {
      setCardNumber(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage(Swal.fire("Only Numbers are allowed!"));
    }
  };
  const handleCardNameChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only letters
    const isValid = /^[A-Za-z\s]+$/.test(inputValue);

    if (isValid || inputValue === "") {
      setCardName(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage(Swal.fire("Only Alphabets are allowed!"));
    }
  };
  const handleCVVChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only numbers
    const isValid = /^\d+$/.test(inputValue);

    if (isValid || inputValue === "") {
      setCvvnum(inputValue);
      setErrorMessage("");
    } else {
      setErrorMessage(Swal.fire("Only Numbers are allowed!"));
    }
  };
  // };
  // converge starts
  const handleCloseConverge = () => setShowConverge(false);
  const handleConvergePay = (e) => {
    e.preventDefault()
    if (CardName.toString().trim() === "") {
      Swal.fire({
        icon: "error",
        title:
          "Please enter card holder name",
      });

      return;
    }
    if (
      CardName.toString()
        .trim()
        .toLowerCase()
        .indexOf(fname.toString().trim().toLowerCase()) < 0
    ) {
      Swal.fire({
        icon: "error",
        title:
          "Name on the credit card should match with the name of driver/renter.",
      });

      return;
    }
    if (CardNumber.toString().trim().length < 14 || CardNumber.toString().trim().length > 16) {
      Swal.fire({
        icon: "error",
        title:
          "Please enter valid card number",
      });

      return;
    }
    if (!ExpiryDate) {
      Swal.fire({
        icon: "error",
        title:
          "Please select card expiry date",
      });

      return;
    }

    if (!CVVNum) {
      Swal.fire({
        icon: "error",
        title:
          "Please enter CVC",
      });

      return;
    }

    // all card ui verification ends here
    const item = { ...itemDetails }
    item['c_name'] = CardName
    item['c_number'] = CardNumber
    item['c_expiry_m'] = month.toString()
    item['c_expiry_y'] = year.toString()
    item['c_code'] = CVVNum

    processPayment(item)


  }



  return (
    <>
      <div className="container mt-5">

        <div className="row">
          <div className="col-12 col-lg-4">
            <h4 style={{ textAlign: "center", fontFamily: "Arial" }}>Rental Details</h4>
            <div
              className="info-container"
              style={{
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              <div className="row" id="container-row">
                <div className="col-12 col-md-12 col-sm-12">
                  <h4
                    style={{
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      fontSize: "16px",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    Pick-up Address
                  </h4>
                  <i className="bi bi-geo-alt-fill" style={{ color: "#ff6000" }}></i>
                  <span
                    style={{
                      marginLeft: "1em",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                  >
                    {sessionStorage.getItem("locationSearch")}
                    <br />
                    {sessionStorage.getItem("locationAddress")}
                  </span>
                  <div
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                  </div>
                </div>
                <div className="col-12 col-md-12 col-sm-12 mb-3">
                  <h4
                    style={{
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      fontSize: "16px",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    Pick-up Date
                  </h4>
                  <i className="bi bi-calendar" style={{ color: "#ff6000" }}></i>
                  <span
                    style={{
                      marginLeft: "1em",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                  >
                    {moment(fromDate).format("DD-MM-YYYY") + "," + startTime}
                  </span>
                  <div
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                  </div>
                </div>
                <div className="col-12 col-md-12 col-sm-12">
                  <h4
                    style={{
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      fontSize: "16px",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    Drop-off Address
                  </h4>
                  <i className="bi bi-geo-alt-fill" style={{ color: "#ff6000" }}></i>
                  <span
                    style={{
                      marginLeft: "1em",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                  >
                    {sessionStorage.getItem("dropLocationSearch")}
                    <br />
                    {sessionStorage.getItem("dropLocationAddress")}
                  </span>
                  <div
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                  </div>
                </div>
                <div className="col-12 col-md-12 col-sm-12">
                  <h4
                    style={{
                      fontFamily: "Arial",
                      fontWeight: "bold",
                      fontSize: "16px",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    Drop-off Date
                  </h4>
                  <i className="bi bi-calendar" style={{ color: "#ff6000" }}></i>
                  <span
                    style={{
                      marginLeft: "1em",
                      fontFamily: "Arial",
                      fontSize: "14px",
                    }}
                  >
                    {moment(toDate).format("DD-MM-YYYY") + "," + endTime}
                  </span>
                  <div
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>
            <h4 style={{ textAlign: "center", fontFamily: "Arial" }}>Vehicle Details</h4>
            <div
              className="info-container"
              style={{
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              <div className="row" id="container-row">
                <div className="col-12 col-md-12 col-sm-12">
                  <span
                    style={{
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "bold"
                    }}
                  >
                    {data?.fleet_detail?.car_type ? data?.fleet_detail?.car_type : "N/A"}<br />
                    {data?.fleet_detail?.make} {data?.fleet_detail?.model} <span style={{ fontWeight: "initial" }}>or Similar</span>
                  </span>
                </div>
                <div className="col-12 col-md-12 col-sm-12 mb-3">
                  <img
                    src={data?.fleet_detail?.image_path.indexOf("//") > 0
                      ? data?.fleet_detail?.image_path
                      : baseURL + "/static/img/" + data?.fleet_detail?.image_path.replace(/ /g, "%20")}
                    alt="code"
                    style={{ width: "auto", height: "200px" }}
                    className="img-fluid"
                  />
                </div>
                <div className="col-12 col-md-12 col-sm-12 mb-3">
                  <span
                    style={{
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "bold"
                    }}
                  >
                    By selecting Book & Pay you agree to the <span
                      style={{
                        color: "#ff6000",
                        cursor: "pointer",
                        fontFamily: "Arial",
                      }}
                      onClick={() => handleOpenModal()}
                    >
                      Rental Car Policy and Rules
                    </span>
                  </span>

                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            {/* Policy Modal starts */}
            {isModalOpen && (
              <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                size="xl"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton style={{ color: "#ff6000" }}>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Rental Policy
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{ overflowY: "auto", maxHeight: "90vh", maxWidth: "90vw", padding: "20px 20px 20px 0px" }}
                >
                  {policyDetails &&
                    policyDetails.map((policy, index) => (policy.content != "" &&
                      <ul className="tit" key={index}>
                        <li style={{ paddingBottom: "10px" }}>
                          <h3
                            className="fw-normal"
                            style={{
                              fontSize: "1.5rem",
                              fontFamily:
                                "Arial",
                            }}
                          >
                            {policy.name}
                          </h3>
                          <p
                            className="lead"
                            style={{
                              fontSize: "1.1rem",
                              fontFamily:
                                "Arial ",
                              textAlign: "justify",
                            }}
                          // }}
                          >
                            {policy.content}
                          </p>
                        </li>
                      </ul>
                    ))}
                </Modal.Body>
              </Modal>
            )}
            {/* Policy Modal ends */}
            {/* {ReservationConfirmed Modal start} */}
            {Loading ? (
              //
              <div
                className=""
                style={{
                  justifyContent: "center",
                  display: "flex",
                  gap: "5px",
                  position: "fixed",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "1",
                  backgroundColor: "rgba(4, 4, 4, 0.3)",
                  paddingTop: "200px"
                }}
              >
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only"></span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only"></span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            ) : (
              <Modal show={show} onHide={handleClose} animation={false} backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title style={{ color: "#ff6000" }}>
                    Reservation Confirmed
                  </Modal.Title>
                  <div></div>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    {/* <h4 style={{ color: "black" }}>
                {"Reservation Number: " + rezNum}
              </h4> */}
                    <h4 style={{ color: "black" }}>
                      Reservation Number:{" "}
                      <span style={{ color: "#ff6000" }}>{rezNum}</span>
                    </h4>
                  </div>
                  <div>
                    <br />
                    <p>
                      Thank you for your Booking. For any queries contact us at{" "}
                      <Link
                        to="mailto:customercare@routes.ca"
                        style={{ color: "#ff6000" }}
                      >
                        customercare@routes.ca
                      </Link>
                      &nbsp; or call us at{" "}
                      <Link to="tel:" style={{ color: "#ff6000" }}>
                        <span className="ps-1">16138222409</span>
                      </Link>
                    </p>
                  </div>
                  <div>
                    <p style={{ color: "gray" }}>
                      Confirmation mail has been sent to Registered Email Address
                      ,if the e-mail is not in your inbox, please check your junk
                      or spam folders.
                    </p>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {/* Reservation Confirmed modal closed */}
            {/* taxes pop up starts */}
            <Modal show={show2} onHide={handleClose2}>
              <Modal.Header closeButton style={{ backgroundColor: '#ff6000' }}>
                <Modal.Title>Tax & Fee Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "16px",
                        }}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "16px",
                        }}
                      >
                        Rate
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "16px",
                        }}
                      >
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.taxes_detail != undefined &&
                      data?.taxes_detail?.independent_tax != undefined &&
                      data?.taxes_detail?.independent_tax.map((val) => (
                        <tr>
                          <td
                            scope="row"
                            style={{
                              fontFamily:
                                "Arial",
                              fontSize: "14px",
                            }}
                          >
                            {val.tax_name}
                          </td>
                          <td style={{ fontSize: "14px" }}>
                            {currency_symbol}
                            {val.amount} {val.per_day_per_rental === "Per Day"
                              ? " / Day"
                              : " / Rental"}
                            {/* // " " +
                                // (val.currency === undefined
                                //   ? ""
                                //   : val.currency)} */}
                          </td>
                          <td style={{ fontSize: "14px" }}>
                            {currency_symbol}
                            {data?.taxes_detail?.independent_tax_charge[
                              val.tax_name
                            ].toFixed(2)}{" "}
                            {/* {currency} */}
                          </td>
                        </tr>

                      ))}

                    {data?.taxes_detail != undefined &&
                      data?.taxes_detail?.baserate_tax != undefined &&
                      data?.taxes_detail?.baserate_tax.map((val) => (
                        <tr>
                          <td
                            scope="row"
                            style={{
                              fontFamily:
                                "Arial",
                              fontSize: "14px",
                            }}
                          >
                            {val.tax_name}
                          </td>
                          <td>{val.percentage + "%"}</td>
                          <td style={{ fontSize: "14px" }}>
                            {currency_symbol}
                            {data?.taxes_detail?.baserate_tax_charge[
                              val.tax_name
                            ].toFixed(2)}{" "}
                            {/* {currency} */}
                          </td>
                        </tr>
                      ))}

                    {data?.taxes_detail != undefined &&
                      data?.taxes_detail?.calculated_tax != undefined &&
                      data?.taxes_detail?.calculated_tax.map((val) => (
                        <tr>
                          <td
                            scope="row"
                            style={{
                              fontFamily:
                                "Arial",
                              fontSize: "14px",
                            }}
                          >
                            {val.tax_name}
                          </td>
                          <td>{val.percentage + "%"}</td>
                          <td style={{ fontSize: "14px" }}>
                            {currency_symbol}
                            {data?.taxes_detail?.calculated_tax_charge[
                              val.tax_name
                            ].toFixed(2)}{" "}
                            {/* {currency} */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Modal.Body>
              {/* <Modal.Footer>
          <Button variant="secondary" style={{color:'black', backgroundColor:'#ff6000'}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
            </Modal>
            <h4
              style={{
                textAlign: "center",
                fontFamily: "Arial",
              }}
            >
              Driver Details
            </h4>
            {validationError && (
              <div className="toast alert alert-danger">{validationError}</div>
            )}
            <div
              className="info-container"
              style={{
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              <div className="row" id="container-row">
                <div className="col-12 col-md-6 col-lg-4 col-sm-12">
                  <label
                    htmlFor="firstname"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={fname}
                    onChange={handleFirstName}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-sm-12">
                  <label
                    htmlFor="lastname"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lname}
                    onChange={handleLasttName}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-sm-12">
                  <label
                    htmlFor="phone"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Phone Number*
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Phone Number"
                    value={PhoneNumber}
                    onChange={handlePhoneNumber}
                  />
                </div>
              </div>
              <div className="row" id="container-row">
                <div className="col-12 col-md-6 col-lg-4 col-sm-12">
                  <label
                    htmlFor="Email *"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Email Address*
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={EmailId}
                    onChange={(e) => setEmailID(e.target.value)}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-sm-12">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Confirm Email Address*
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Confirm Email Address"
                    value={ConfEmailId}
                    onChange={(e) => setConfEmailID(e.target.value)}
                  />
                </div>
                <div className="customiseDatePickerWidth col-12 col-md-6 col-lg-4">
                  <label
                    htmlFor="lastname"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Date of Birth*
                  </label>

                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="date-picker-container border-l rounded text-sm w-100 form-control"
                    showIcon={true}
                    selected={DOB}
                    dateFormat="dd-MM-yyyy"
                    maxDate={maxDate}
                    // minDate={new Date()}
                    placeholderText="Date of Birth"
                    onChange={onChange}
                    style={{
                      fontWeight: "400",
                      fontFamily: "Arial",
                    }}
                  />
                </div>
              </div>

              <div className="row" id="container-row">
                <div className="col-12 col-md-12 col-lg-8">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Address*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    placeholder="Address"
                    value={CustomerAddr}
                    onChange={(e) => setCustomerAddr(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-6">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Country*
                  </label>
                  <Select
                    placeholder={"Select Country"}
                    options={Country.getAllCountries()}
                    value={country}
                    getOptionLabel={(options) => options.name}
                    getOptionValue={(options) => options.name}
                    onChange={(e) => {
                      setCountry(e);
                      handleCountry(e);
                    }}
                  />
                </div>
              </div>
              <div className="row" id="container-row">
                <div className="col-lg-4 col-md-6">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    State/Province*
                  </label>
                  <Select
                    placeholder={"Select State/Province"}
                    value={state}
                    options={stateData}
                    getOptionLabel={(options) => options.name}
                    getOptionValue={(options) => options.name}
                    onChange={(e) => {
                      setState(e);
                      handleState(e);
                    }}
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    City
                  </label>
                  <Select
                    placeholder={"Select City"}
                    value={city}
                    options={cityData}
                    getOptionLabel={(options) => options.name}
                    getOptionValue={(options) => options.name}
                    onChange={(e) => {
                      setCity(e);
                      handleCity(e);
                    }}
                  />
                </div>

                <div className="col-md-6 col-lg-4">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    ZIP Code*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ZIP Code"
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                  />
                </div>
              </div>
              <div className="row" id="container-row">
                <div className="col-md-6 col-lg-4">
                  <label
                    htmlFor="email"
                    style={{
                      fontFamily: "Arial",
                    }}
                  >
                    Flight Details/Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Flight Details/Number"
                    value={flight_number}
                    onChange={(e) => setflight_number(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* paymet info */}


            {/* pricing information */}
            <h4
              style={{
                textAlign: "center",
                fontFamily: "Arial",
              }}>
              Pricing Information
            </h4>
            <div
              className="info-container"
              style={{
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              <div className="row" id="container-row">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "16px",
                        }}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "16px",
                        }}
                      >
                        Rate
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "16px",
                        }}
                      >
                        Subtotal
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "14px" }}>
                    <tr>
                      <td
                        scope="row"
                        style={{
                          fontFamily:
                            "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <span style={{ fontSize: "14px" }}>DAY(s):</span>
                        &nbsp;{sessionStorage.getItem("lor")}
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        {currency_symbol}
                        {(
                          data?.price / sessionStorage.getItem("lor")
                        ).toFixed(2)}
                        {" / Day"}
                        {/* {currency} */}
                      </td>
                      <td style={{ fontSize: "14px" }}>
                        {currency_symbol}
                        {data?.price.toFixed(2)}&nbsp;
                        {/* {currency} */}
                      </td>
                    </tr>
                    <tr>
                      {/* <td><span style={{
                  cursor: 'pointer', color: '#ff6000',
                  textDecoration: 'underline',
                }} onClick={() => setShow2(true)}>Tax & Fee Details</span></td> */}
                      <td><span >Tax & Fee</span></td>
                      <td></td>
                      <td>{currency_symbol}{data?.total_taxes.toFixed(2)}</td>
                    </tr>

                    {Extras.length > 0 &&
                      Extras.map(
                        (val) =>
                          val.count > 0 && (
                            <tr>
                              <td
                                scope="row"
                                style={{
                                  fontFamily:
                                    "Arial",
                                }}
                              >
                                {val.name}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {currency_symbol}
                                {val.base_rate.toFixed(2).toString()}
                                {val.type === "Per Day"
                                  ? " / Day"
                                  : " / Rental"}
                                &nbsp;
                              </td>
                              <td>
                                {currency_symbol}
                                {(val.total * val.count).toFixed(2)}&nbsp;
                              </td>
                            </tr>
                          )
                      )}
                    {
                      // if Europcar
                      JSON.parse(sessionStorage.getItem("pickLocation"))
                        .source === "europcar" ? (
                        <>
                          <tr>
                            <td
                              scope="row"
                              style={{
                                fontFamily:
                                  "Arial",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Pay Now
                            </td>
                            <td></td>
                            <td
                              style={{ fontWeight: "600", fontSize: "14px" }}
                            >
                              {currency_symbol}
                              {(data.price_after_taxes + +extrasTotal).toFixed(2) == undefined || isNaN((data.price_after_taxes + +extrasTotal).toFixed(2))
                                ? (
                                  (15 * (1 * data?.price_after_taxes)) /
                                  100
                                ).toFixed(2)
                                : ((15 * (1 * (data.price_after_taxes + +extrasTotal).toFixed(2))) / 100).toFixed(
                                  2
                                )}{" "}
                              {Currency}
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="row"
                              style={{
                                fontFamily:
                                  "Arial",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Pay At Counter
                            </td>
                            <td></td>
                            <td
                              style={{ fontWeight: "600", fontSize: "14px" }}
                            >
                              {currency_symbol}
                              {(data.price_after_taxes + +extrasTotal).toFixed(2) == undefined || isNaN((data.price_after_taxes + +extrasTotal).toFixed(2))
                                ? (
                                  (85 * (1 * data?.price_after_taxes)) /
                                  100
                                ).toFixed(2)
                                : ((85 * (1 * (data.price_after_taxes + +extrasTotal).toFixed(2))) / 100).toFixed(
                                  2
                                )}{" "}
                              {Currency}
                            </td>
                          </tr>
                          <tr>
                            <td
                              scope="row"
                              style={{
                                fontFamily:
                                  "Arial",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Total
                            </td>
                            <td></td>
                            <td
                              style={{ fontWeight: "600", fontSize: "14px" }}
                            >
                              {currency_symbol}
                              {(data.price_after_taxes + +extrasTotal).toFixed(2) == undefined || isNaN((data.price_after_taxes + +extrasTotal).toFixed(2))
                                ? data?.price_after_taxes
                                : (data.price_after_taxes + +extrasTotal).toFixed(2)}{" "}
                              {Currency}
                            </td>
                          </tr>
                        </>
                      ) : (
                        // if Routes
                        <tr>
                          <td
                            scope="row"
                            style={{
                              fontFamily:
                                "Arial",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            TOTAL
                          </td>
                          <td></td>
                          <td style={{ fontWeight: "600", fontSize: "14px" }}>
                            {currency_symbol}
                            {(data.price_after_taxes + +extrasTotal).toFixed(2) == undefined || isNaN((data.price_after_taxes + +extrasTotal).toFixed(2))
                              ? data?.price_after_taxes
                              : (data.price_after_taxes + +extrasTotal).toFixed(2)}{" "}
                            {Currency}
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="info-container d-none"
              style={{
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              <div className="row" id="container-row">
                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="vehicle-name"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Vehicle type
                  </label>
                  <h6
                    style={{ color: "black", fontSize: "14px", fontWeight: "400" }}
                  >
                    {data.fleet_detail.description}
                  </h6>
                </div>
                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="location"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Number Of Days
                  </label>

                  <h6
                    style={{ color: "black", fontSize: "14px", fontWeight: "400" }}
                  >
                    {datas}
                  </h6>
                </div>
                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="booking-time"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Payment type
                  </label>
                  <h6
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {JSON.parse(sessionStorage.getItem("pickLocation")).source ===
                      "europcar"
                      ? "Partial Payment"
                      : "PrePaid"}
                  </h6>
                </div>

                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="bookinginterval"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Total amount
                  </label>
                  <h6
                    style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
                  >
                    {" "}
                    {currency_symbol}
                    {(data.price_after_taxes + +extrasTotal).toFixed(2)}&nbsp;
                    {Currency}
                  </h6>
                </div>
              </div>
              <hr></hr>
              <div className="row" id="container-row">
                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="nameofclient"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Pick-up Location
                  </label>
                  <h6
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {sessionStorage.getItem("locationSearch")}
                  </h6>
                </div>

                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="packagebooking"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Pick-up Date & Time
                  </label>
                  <h6
                    style={{ color: "black", fontSize: "14px", fontWeight: "400" }}
                  >
                    {moment(fromDate).format("DD-MM-YYYY") + "," + startTime}
                  </h6>
                </div>

                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="loc"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Drop-off Location
                  </label>
                  <h6
                    style={{
                      color: "black",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {sessionStorage.getItem("dropLocationSearch")}
                  </h6>
                </div>

                <div className="col-12 col-md-3 col-sm-12">
                  <label
                    htmlFor="totalamount"
                    style={{
                      color: "f9f9f9",
                      fontFamily: "Arial",
                    }}
                  >
                    Drop-off Date & Time
                  </label>
                  <h6
                    style={{ color: "black", fontSize: "14px", fontWeight: "400" }}
                  >
                    {moment(toDate).format("DD-MM-YYYY") + "," + endTime}
                  </h6>
                </div>
              </div>
            </div>

            {/* button */}
            <div className="row p-0" id="confirmpayment-row">
              <div className="col-md-12 mt-4" id="confirmpayment-desc-col">
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </label>
                <span
                  style={{
                    paddingLeft: "10px",
                    fontFamily: "Arial",
                  }}
                >
                  I have read and accepted the{" "}
                </span>
                <span
                  style={{
                    color: "#ff6000",
                    cursor: "pointer",
                    fontFamily: "Arial",
                  }}
                  onClick={() => handleOpenModal()}
                >
                  terms & conditions*
                </span>
              </div>
              <div className="col-md-12 mt-1" id="confirmpayment-desc-col">
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked2}
                    onChange={handleCheckboxChange2}
                  />
                </label>
                <span
                  style={{
                    paddingLeft: "10px",
                    fontFamily: "Arial",
                  }}
                >
                  By checking this box you agree to receive recurring messages from Routes Car Rental, Reply STOP to Opt out. Reply HELP for help. Message frequency varies. Message and data rates may apply. Carriers are not liable for delayed or undelivered messages.
                </span>
              </div>
              <div className="col-md-6 d-none" style={{ textAlign: "right" }}>
                <h4
                  style={{
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#ff6000",
                  }}
                >
                  Service Provided by
                </h4>
                <img
                  src={sourceValue === "rms" ? routesCarImage : europCarImage}
                  alt=""
                  className=""
                  width={"97px"}
                />{" "}
              </div>
              <div className="">
                <p className="captcha_text">Enter the text from the image:</p>
                <div className="captcha">
                  {captchaText.split("").map((char, index) => (
                    <span key={index} className="captcha-char">
                      {char}
                    </span>
                  ))}
                  <span style={{ cursor: "pointer" }} onClick={generateCaptcha}>
                    <RefreshIcon />
                  </span>
                </div>
                <input
                  type="text"
                  value={userInput}
                  onChange={handleInputChange}
                  className="captcha-input captcha_text"
                  placeholder="Enter here ..."
                />
              </div>
              <div
                className="col-md-6 mt-4 col-sm-6 mb-4 "
                id="confirmpayment-btn-col"
              >
                <button
                  type="button"
                  className="btn btn btn-rounded"
                  onClick={(e) => handlePayNow(e)}
                  style={{
                    backgroundColor: "#ff6000",
                    height: "55px",
                    fontFamily: "Arial",
                    width: "176px",
                  }}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {// SkyScanner Pixel starts

          ss_skyscanner_redirect && ss_skyscanner_redirect != undefined && (sessionStorage.getItem('SkyScannerPage') != undefined && sessionStorage.getItem('SkyScannerPage') != 'checkout-page') &&
          <>
            <img src={`https://analytics.skyscanner.net/dv/v1/event.png?${ss_final_key_value_pre}`}
              style={{ "width": "1px", "height": "1px", "border-style": "none" }} onLoad={() => { sessionStorage.setItem('SkyScannerPage', 'checkout-page'); }} />

          </>
        }
        {
          paymentConfirmed &&
          <img src={`https://analytics.skyscanner.net/dv/v1/event.png?${ss_final_key_value}`}
            style={{ "width": "1px", "height": "1px", "border-style": "none" }} />

        }

      </div>
      <Modal
        show={showConverge}
        onHide={handleCloseConverge}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="row" style={{ alignItems: "baseline", fontFamily: "Arial" }}>
              <div className="col-6" style={{ textAlign: "right" }}><h4>Billing</h4></div>
            </div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* paymet info */}

          <div
            className="info-container"
            style={{
              backgroundColor: "white",
              padding: "0rem",
              borderRadius: "10px",
            }}
          >
            <div className="row" id="container-row">

              <div>

                <ul class="credit_card_img">
                  <li class="credit_card_img_li">
                    <img src={cc_master_card} style={{ width: "70%" }} />
                  </li>

                  <li class="credit_card_img_li">
                    <img src={cc_american_express} style={{ width: "70%" }} />
                  </li>

                  <li class="credit_card_img_li">
                    <img src={cc_visa} style={{ width: "70%" }} />
                  </li>
                </ul>
              </div>

              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                    <label
                      htmlFor="title"
                      style={{
                        fontFamily: "Arial",
                      }}
                    >
                      Card Holder's Name*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name On Card"
                      value={CardName}
                      onChange={handleCardNameChange}
                    />
                  </div>
                  <div className="col-12 col-lg-6 col-md-6 ">
                    <label
                      htmlFor="cardnumber"
                      style={{
                        fontFamily: "Arial",
                      }}
                    >
                      Credit Card Number*
                    </label>
                    <input
                      id="ccn"
                      type="password"
                      className="form-control"
                      inputmode="numeric"
                      pattern="[0-9]{13,19}"
                      autocomplete="cc-number"
                      maxlength="16"
                      placeholder="xxxx xxxx xxxx xxxx"
                      value={CardNumber}
                      onChange={handleCardNumberChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div id="date-picker-example" className="col md-form col-lg-6 col-md-6 md-outline input-with-post-icon datepicker customiseDatePickerWidth" inline="true">
                    <label
                      htmlFor="expiry"
                      style={{
                        fontFamily: "Arial",
                      }}
                    >
                      Expiry*
                    </label>

                    <DatePicker
                      autocomplete="off"
                      aria-autocomplete="none"
                      placeholderText="Expiry Date"
                      selected={ExpiryDate}
                      minDate={new Date()}
                      showIcon={true}
                      // selected={new Date(ExpiryDate)}
                      className="date-picker-container border-l rounded text-sm w-100 form-control"
                      renderMonthContent={renderMonthContent}
                      onChange={onChangetwo}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      filterDate={filterPastMonths} // Apply filter function
                    />
                  </div>
                  <div className="col-12  col-lg-6 col-md-6">
                    <label
                      htmlFor="cvc"
                      style={{
                        fontFamily: "Arial",
                      }}
                    >
                      CVC*
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      size="1"
                      minlength="3"
                      maxlength="4"
                      placeholder="Enter CVC"
                      value={CVVNum}
                      onChange={handleCVVChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {/* <Button variant="secondary" onClick={handleCloseConverge}>
            Close
          </Button> */}
          {
            convergePayLoading ? <Button style={{
              backgroundColor: "#ff6000",
              height: "45px",
              fontFamily: "Arial",
              // width: "76px",
              width: "auto",
              border: 0
            }}
              disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;Paying ${parseFloat(amountToPay).toFixed(2)}
            </Button> :
              <Button className="btn btn btn-rounded" style={{
                backgroundColor: "#ff6000",
                height: "45px",
                fontFamily: "Arial",
                // width: "76px",
                width: "auto",
                border: 0
              }}
                onClick={handleConvergePay}
              >Pay ${parseFloat(amountToPay).toFixed(2)}</Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CheckOut;
