import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
// import favicon from "../Page/images/favicon.ico";
import europcarImage from "../Page/asset/europcar_img1.jpg";
import routesImage from "../Page/asset/routeslogo.png";
import PCI from "../Page/asset/pci-dss-1.png.webp";
import "bootstrap-icons/font/bootstrap-icons.css";

import {
  FaTelegram,
  FaFacebook,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

const Footer = () => {
  const scrollToPercentage = (percentage) => {
    const windowHeight = window.innerHeight;
    const scrollToPosition = (percentage / 100) * windowHeight;
    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth' // Optional: Add smooth scrolling effect
    });
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const handleBookNowClick = () => {
    window.location.href = "/"; // Redirect to the home page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Scroll to the top of the page smoothly
    });
  };

  const handleTravelAgentClick = () => {
    window.location.href = "/TravelAgent"; // Redirect to the contact page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlecontactClick = () => {
    window.location.href = "/ContactUs"; // Redirect to the travel agent page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlerentClick = () => {
    window.location.href = "/directfleet"; // Redirect to the rent page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLocationpageClick = () => {
    window.location.href = "/Locationpage"; // Redirect to the Location page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleRentalPolicyClick = () => {
    window.location.href = "/RentalPolicy"; // Redirect to the RentalPolicy page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };const handleSmsPolicyClick = () => {
    window.location.href = "/SmsPolicy"; // Redirect to the SmsPolicy page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    // <div>
    <div
      className="footer"
      style={{
        // marginTop: "129px",
        fontFamily: "Arial",
        backgroundColor: "white",
      }}
    >
      <div className="row w-100 p-3">
      <div className="col-2 col-lg-2 col-md-2 col-sm-2 footer-col" >
      <span
            className="Scrolltotopstyle"
            style={{
              borderRadious: "50px",
              color: "#ff6000",
              backgroundColor: "#ff6000",
            }}
          >
            <ScrollToTop
              smooth
              style={{ backgroundColor: "#ff6000", marginInline: "-35px", padding:"5px" }}
              className="d-flex justify-content-center d-none"
            />
          </span>
      </div>
        <div
          className="col-3 col-lg-3 col-md-3 col-sm-3 footer-col d-none"
          id="logos"
          style={{ width: "" }}
        >
          <span
            className="Scrolltotopstyle"
            style={{
              borderRadious: "50px",
              color: "#ff6000",
              backgroundColor: "#ff6000",
            }}
          >
            <ScrollToTop
              smooth
              style={{ backgroundColor: "#ff6000", marginInline: "-35px", padding:"5px" }}
              className="d-flex justify-content-center"
            />
          </span>

          <ul
            className="footer-list"
            style={{
              position: "relative",
              marginLeft: "5rem",
              alignItems: "center",
            }}
          >
            {" "}
          </ul>
          <li style={{ listStyleType: "none" }} className="mx-4 text-center">
            <Link to="/">
              <img
                src={routesImage}
                alt=""
                className="routes"
                // width={"140rem"}
              />{" "}
            </Link>
          <p className="mt-2"><b>In Alliance With</b></p>
            <Link to="/">
              <img
                src={europcarImage}
                alt=""
                className="europcarlogo"
                // width={"140rem"}
              />{" "}
            </Link>
          </li>

          {/* <div className="row-md-2 mx-4 mt-3">
            <p className="routes-des">
              Routes Car Rental is a privately owned and operated car rental
              company that is based and headquartered in Toronto Canada.
            </p>
          </div> */}
        </div>
        
        <div className="footer-col d-none">
          <h4>Company</h4>
          <ul
            className="footer-list"
            style={{ position: "relative", right: "33px", padding: "0px" }}
          >
            <li>
              <Link to="/" onClick={handleBookNowClick}>
                Home
              </Link>
            </li>

            <li>
              <Link to="/ContactUs" onClick={handlecontactClick}>
                About us
              </Link>
            </li>

            <li>
              <Link to="#">Online Check-In</Link>
            </li>

            <li>
              <Link to="/TravelAgent" onClick={handleTravelAgentClick}>
                Travel agents
              </Link>
            </li>

            <li>
              <Link to="/directfleet" onClick={handlerentClick}>
                Rent
              </Link>
            </li>

            <li>
              <Link to="/ContactUs" onClick={handlecontactClick}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-3 footer-col">
          <h4>Services</h4>
          <ul
            className="footer-list"
            style={{
              position: "relative",
              padding: "0px",
              //  right: "33px"
            }}
          >
            <li>
              <Link to="/directfleet" onClick={handlerentClick}>
                Rent a Vehicle
              </Link>
            </li>

            <li>
              <Link to="#" onClick={() => scrollToPercentage(80)}>Blog & News</Link>
            </li>
            <li>
              <Link to="/Locationpage" onClick={handleLocationpageClick}>
                Location maps
              </Link>
            </li>
            <li>
              <Link to="/" onClick={handleBookNowClick}>
                Reserve now
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 footer-col">
          <h4>Site map</h4>
          <ul
            className="footer-list"
            style={{
              position: "relative",
              padding: "0px",
              //  right: "33px"
            }}
          >
            <li>
              <Link to="/" onClick={handleBookNowClick}>
                Reserve now
              </Link>
            </li>

            <li>
              <Link to="/directfleet" onClick={handlerentClick}>
                Our Fleet
              </Link>
            </li>

            <li>
              <Link to="/Locationpage" onClick={handleLocationpageClick}>
                Locations
              </Link>
            </li>
            <li>
              <Link to="/RentalPolicy" onClick={handleRentalPolicyClick}>
                Rental policies
              </Link>
            </li>
            <li>
              <Link to="/SmsPolicy">SMS privacy</Link>
            </li>
            <li>
              <Link to="/SmsAlerts">SMS alerts</Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 footer-col">
          <h4>Partner links</h4>
          <ul
            className="footer-list"
            style={{
              position: "relative",
              padding: "0px",
              // right: "33px"
            }}
          >
            <li>
              <Link to="https://www.routesairportparking.com/#Quotes" target="_blank">Routes airport parking</Link>
            </li>
            <li>
              <Link to="https://www.routesautosales.com/" target="_blank">Routes auto remarketing</Link>
            </li>
            <li>
              <Link to="https://routesautohaulage.com/" target="_blank">Routes auto haulage</Link>
            </li>
            {/* <li>
              <Link to="https://routesrez.com/">Routes car global LLC</Link>
            </li> */}
            {/* <li>
              <Link to="https://www.europcar.com/en-us" target="_blank">Europcar.com</Link>
            </li> */}
            <li style={{display:"flex",gap:"1rem"}}>
              <img
                src={PCI}
                alt=""
                className="logo_footer"
                style={{ width: "4rem" }}
              />
              <a>
              <img
                src={europcarImage}
                alt=""
                className="europcarlogo"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <div className="coprights-row">
        <div className="copyrights-col px-3">
          <div className="copy-rights-desc">
            © 2023 RCR, All rights reserved
          </div>
        </div>
        <div className="copyrights-col px-3">
          <div className="privacy-policy">
            Privacy Policy . Terms & Conditions
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
